// polyfils should be included as separate script before all.js
// import '@progress-wad/site-polyfills/build/polyfills.js';

import './modules/dataloader.js';
import './modules/support-css-property.js';
import './modules/section-banner.js';
import './modules/section-patch.js';
import './modules/jquery.tabs.js';
import './modules/jquery.accordion.js';
import './modules/site-specific.js';
import './modules/contextual-nav';
import './modules/fragments';
import './modules/toggle-self.js';
import './modules/toggle.js';
import './modules/social-share.js';
import './modules/outline-nav.js';
import './modules/forms-floating-labels';
import './modules/replacement-query-parameter.js';
import '@progress-wad/litebox';
import '@progress-wad/siema-slider';
import '@progress-wad/sticky-element';
import '@progress-wad/site-search/build/index.es6.js';
import '@progress-wad/site-search/build/init.es6.js';
import '@progress-wad/youtube-lite-embed/dist/index.mjs';

import { Select } from '@progress-wad/select-ui/src/select.js';
window['Select'] = Select;

import { MultiSelect } from '@progress-wad/multi-select';
document.addEventListener('DOMContentLoaded', () => {
  MultiSelect.init();
});
